import { Star } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PurchaseOptionsVariant } from "../../types";
import { gql, useQuery } from "@apollo/client";

type Props = {
  variant: PurchaseOptionsVariant;
};

type Tier = {
  title: string;
  subheader?: string;
  price: string;
  description: string[];
  buttonText: string;
  buttonVariant: "outlined" | "contained";
  stripeLink?: string;
};

const PurchaseOptions = (props: Props) => {
  const { data: me_data, loading: me_loading } = useQuery(
    gql`
      query {
        me {
          id
        }
      }
    `,
    {
      fetchPolicy: "cache-first",
    }
  );

  const get_tiers = (id: string): Tier[] => [
    {
      title: "1 Component",
      price: "$7",
      description: [
        "1 Appraisal Token which can be used on any single component", "\b"
      ],
      buttonText: "Choose",
      buttonVariant: "outlined",
      stripeLink: `https://buy.stripe.com/00g6pbeSMbsg8hy8wx?client_reference_id=${id}`,
    },
    {
      title: "1 Custom Build (3+ Components)",
      subheader: "Value pick",
      price: "$19",
      description: ["3 Appraisal Tokens for use on any build of 3+ components", "\b"],
      buttonText: "Choose",
      buttonVariant: "contained",
      stripeLink: `https://buy.stripe.com/bIY28V3a48g455mcMO?client_reference_id=${id}`,
    },
    {
      title: "Enterprise",
      price: "Let's talk!",
      description: [
        "Track unlimited builds/components in your Garage",
        "Pay for appraisal tokens with bulk discounts or as needed",
      ],
      buttonText: "Contact Us",
      buttonVariant: "outlined",
    },
  ];

  const [tiers, set_tiers] = useState<Tier[]>([]);
  useEffect(() => {
    set_tiers(get_tiers(me_data?.me?.id ?? ""));
  }, [me_loading]);

  return (
    <Grid container mt={4}>
      <Grid item>
        {props.variant === PurchaseOptionsVariant.initial && (
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Sell your PC parts for potentially hundreds more
          </Typography>
        )}
        {props.variant === PurchaseOptionsVariant.closing && (
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Get the most value for your PC
          </Typography>
        )}
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
        >
          Whether you have a spare GPU or a warehouse of builds, we will help
          you get the most value out of your hardware.
        </Typography>
        <Grid sx={{ p: 10 }} container spacing={4} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card sx={{ minHeight: 350, overflowY: 'visible' }}>
                <CardHeader
                  sx={{ position: "relative" }}
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    left: "0",
                    right: "0",
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlign: "center",
                    position: "absolute",
                  }}
                  action={tier.title === "1 Custom Build" ? <Star /> : null}
                />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item sx={{ textAlign: "center" }} xs={12}>
                      <div>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="textPrimary"
                        >
                          {tier.price}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item sx={{ textAlign: "center" }} xs={12}>
                      {tier.description.map((line) => (
                        <Typography
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions disableSpacing={false}>
                  <Button
                    href={tier.stripeLink}
                    disabled={me_loading}
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary"
                    sx={{ m: 4 }}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PurchaseOptions;
