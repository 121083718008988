import React from "react";
import PropTypes from "prop-types";
import Header_Layout from "../../components/layouts/Header_Layout";
import { PurchaseOptionsVariant } from "../../types";
import { HeadFC } from "gatsby";
import AppraisalPurchaseOptions from "../../components/buy-appraisals/AppraisalPurchaseOptions";
import AppraisalPersonas from "../../components/buy-appraisals/AppraisalPersonas";
import AppraisalTimeline from "../../components/buy-appraisals/AppraisalTimeline";

<script async src="https://js.stripe.com/v3/buy-button.js"></script>;

const page_styles = {
  color: "#232129",
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  fontSize: 24,
};

const Pricing = () => {
  return (
    <Header_Layout>
      <main style={page_styles}>
        <AppraisalPurchaseOptions variant={PurchaseOptionsVariant.initial} />
        <AppraisalPersonas />
        {/* <AppraisalFeatures /> */}
        <AppraisalTimeline />
        <AppraisalPurchaseOptions variant={PurchaseOptionsVariant.closing} />
      </main>
    </Header_Layout>
  );
};

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Pricing;

export const Head: HeadFC = () => <title>Pricing - BitLot.app</title>;
